<template lang="pug">
    v-container( fluid  )
        v-row
            v-col
                span(class="tw-text-xl tw-font-bold ") Accounts without planned calls
        v-row
            v-col(cols="4")
                v-select(
                    v-model="staffSelected"
                    :items="staff"
                    item-text="name"
                    item-value="user_name"
                    label="Staff member"
                )
        v-row
            v-col
                v-data-table(
                    :headers="headers"
                    :items="companies"
                    items-per-page=5
                )
                    template( #item="{ item }")
                        tr(class="tw-cursor-pointer tw-font-normal")
                            td( @click="click(item.id)")
                                span {{ item.name }}
                    template( #no-data)
                        div(v-if="!staffSelected")
                            v-row(justify="center")
                                v-col(cols="auto")
                                    div(class="tw-pt-3 tw-text-base")
                                        span() Select a staff memeber to view the report
                                    v-lottie-player(
                                        :loop="true"
                                        width="350px"
                                        height="350px"
                                        :animation-data="require('@/assets/animations/astronaut.json')"  
                                    )
                        div( v-else-if="loading")
                            div(v-if="staffSelected")
                                span loading
                        div( v-else )
                            span no results
        v-dialog( v-model="loading" max-width="520px" persistent  )
            v-card
                v-card-title
                    span We've got this. Sing like no one is listening...
                v-progress-linear(
                    :indeterminate="true"
                    height="4"
                    class="pa-0 ma-0"
                    color="blue"
                )
                    
            
            
                    
</template>
<script>
export default {
    data() {
        return {
            reportName: 'No Planned Calls',
            staffSelected: '',
            staff:'',
            companies: [],
            loading: false,
            headers: [
                {
                    text: 'Account',
                    align: 'left',
                    sortable: false,
                    value: 'name'
                }
            ]
        }
    },
    computed: {
        token () {
            return this.$store.getters.token
        }
    },
    watch: {
    staffSelected (staff) {
        const headers = {
      'Content-Type': 'application/json',
      'OAuth-Token': this.$store.getters.token 
        }   
      this.loading = true
      this.$http.post(this.$api_url + 'reports/companies/without-planned-call', {
        user: staff,
        call_type: 'convDMU',
        call_status: 'Held',
        date: this.$moment().subtract(9, 'Months')
      }, {headers:headers})
        .then(response => {
          this.companies = response.data
          this.loading = false
        })
    }
  },

  mounted() {
      this.$http.get(this.$api_url + 'sales-staff', {
      headers: {
        'OAuth-Token': this.token
      }
    })
      .then(response => {
        this.staff = response.data
      })
  },
  methods:{
          click (item) {
      var win = window.open('http://sugar.xprt.com/#Accounts/' + item, '_blank')
      win.focus()
    }
  }

}
</script>